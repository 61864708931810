import React, { useState } from 'react'
import clsx from 'clsx'

const Carousel = ({
	className,
	items,
	initialActive = 0,
	component: Component,
	itemClassName,
	activeClassName,
}) => {
	const [$active, set$active] = useState(initialActive)

	return <>
		<div className={className}>
			{items?.map((props, key) => (
				<div
					className={clsx('anim-appear', itemClassName, $active === key ? activeClassName : '<md:hidden')}
					key={key}
				>
					<Component {...props} />
				</div>
			))}
		</div>

		<Nav
			className="md:hidden mt-12"
			state={[$active, set$active]}
			size={items.length}
		/>
	</>
}

export const Nav = ({ state, size, className, invert }) => {
	if (size < 2) return null

	const [$active, set$active] = state

	function prev() { set$active(a => a - 1) }
	function next() { set$active(a => a + 1) }

	return (
		<nav className={clsx('flex gap-8', invert && 'invert', className)}>
			<button className="disabled:opacity-20" onClick={prev} title="Previous item" disabled={$active === 0}>
				<img className="-scale-x-100" src="/arrow-right.svg" alt="" />
			</button>

			<button className="disabled:opacity-20" onClick={next} title="Next item" disabled={$active >= size - 1}>
				<img src="/arrow-right.svg" alt="" />
			</button>
		</nav>
	)
}

export default Carousel

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const MetaInfo = ({ data: { pg }, location }) => {
	const { site } = useStaticQuery(query)
	const { metaInfo } = pg

	const title = metaInfo.title?.includes(' | ')
		? metaInfo.title
		: `${metaInfo.title} | ${site.title}`

	return <>
		{/* <link rel="shortcut icon" href="/favicon.png" sizes="any" /> */}
		<link rel="shortcut icon" href="/favicon.svg" type="image/svg+xml" />

		<title>{title}</title>
		<meta name="description" content={metaInfo.description} />

		<meta property="og:title" content={metaInfo.title} />
		<meta property="og:url" content={site.url + location.pathname} />
		<meta property="og:description" content={metaInfo.description} />
		<meta property="og:site_name" content={site.title} />
		{!!site.ogimage && <meta property="og:image" content={site.ogimage.asset.url} />}
	</>
}

export default MetaInfo

const query = graphql`query MetaInfo {
	site: sanitySite {
		title
		url
		ogimage { asset { url } }
	}
}`

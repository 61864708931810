import React from 'react'
import DateCategory from './DateCategory'
import Img from '@/lib/Img'
import Richtext from '@/lib/rt/Richtext'
import * as css from './Content.module.css'
import clsx from 'clsx'
import RtImage from './RtImage'
import { Video } from '@/lib/Video'

const Content = ({ title, date, category, featuredImage, _rawContent }) => (
	<article className="w-full max-w-screen-lg mx-auto grid gap-12">
		<header className="max-w-screen-md mx-auto text-center">
			<h1 className="h0">{title}</h1>
			<DateCategory className="justify-center mt-4" date={date} category={category} />
		</header>

		<Img className="<md:full-width max-h-[70vh]" image={featuredImage} alt={title} />

		<Richtext
			className={clsx(css.content, 'w-full max-w-screen-sm mx-auto richtext')}
			raw={_rawContent}
			components={{
				block: {
					h2: ({ children }) => <h2 className="h2 !font-sans font-bold">{children}</h2>,
					h3: ({ children }) => <h3 className="h3 !font-sans font-bold">{children}</h3>,
				},
				types: {
					img: ({ value }) => <RtImage {...value} />,
					video: ({ value }) => <Video {...value} />,
				},
			}}
		/>
	</article>
)

export default Content

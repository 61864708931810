import React from 'react'
import { urlFor } from '@/utils/sanity'

const height = 750

export const Image = ({ image, alt }) => {
	if (!image) return null

	const src = urlFor(image)
		.height(height)
		.fit('max')
		.auto('format')
		.url()

	const { w, h } = src.match(/-(?<w>\d+)x(?<h>\d+)\./)?.groups

	return (
		<img
			className="mx-auto"
			src={src}
			width={h >= height ? parseInt(w / h * height) : w}
			height={h >= height ? height : h}
			loading="lazy"
			alt={alt}
		/>
	)
}

const ImageWithCaption = ({ caption, ...props }) => (
	<figure className="text-center">
		<Image {...props} />

		{caption && <figcaption className="text-center">{caption}</figcaption>}
	</figure>
)

export default ImageWithCaption

import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '@/layout/Layout'
import MetaInfo from '@/lib/MetaInfo'
import Search from '@/lib/article/Search'
import Actions from '@/lib/article/Actions'
import Content from '@/lib/article/Content'
import ReadNext from '@/lib/article/ReadNext'

const Article = ({ data: { pg } }) => (
	<Layout>
		<section className="section grid md:grid-cols-[auto,1fr] gap-x-8 gap-y-4 items-center">
			<Link className="h3" to="/resources">Resources</Link>
			<Search />
		</section>

		<section className="section grid md:grid-cols-[auto,1fr] gap-8">
			<Actions />
			<Content {...pg} />
		</section>

		<ReadNext current={pg.id} />
	</Layout>
)

export default Article

export const Head = MetaInfo

export const query = graphql`query Article($id: String) {
	pg: sanityArticle(id: { eq: $id }) {
		id
		title
		date
		category {
			name
		}
		featuredImage { asset { gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH) } }
		_rawContent
		metaInfo { ...metaInfo }
	}
}`

import React from 'react'
import format from 'dateformat'
import clsx from 'clsx'

const DateCategory = ({ date, category, className }) => (
	<p className={clsx('flex flex-wrap gap-4', className)}>
		<time className="text-ink/70" dateTime={date}>
			{format(date, 'mmm d, yyyy')}
		</time>
		{'//'}
		<b>{category?.name || 'Article'}</b>
	</p>
)

export default DateCategory

import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PreviewSmall from './PreviewSmall'
import { MdSearch } from 'react-icons/md'

const Search = () => {
	const { allSanityArticle } = useStaticQuery(query)

	const [$query, set$query] = useState(null)
	const [$results, set$results] = useState([])

	function onKeyUp({ target }) {
		set$query(target.value)
		set$results(allSanityArticle.nodes.filter(a => !target.value || a.title.toLowerCase().includes(target.value.toLowerCase())))
	}

	return (
		<div className="relative z-[1]">
			<label className="relative flex items-center">
				<span className="absolute left-2 top-1/2 -translate-y-1/2 grid place-content-center w-[1.5em] pointer-events-none opacity-70">
					<MdSearch className="text-xl" />
				</span>

				<input
					className="w-full border border-ink/10 p-2 pl-[2.5em]"
					type="search"
					onKeyUp={onKeyUp}
					placeholder="Search"
				/>
			</label>

			{!!$query && (
				<div className="absolute inset-x-0 top-full border border-ink/20 p-2 bg-white shadow-lg">
					<p className="text-ink/70">
						{$results?.length} result{$results.length !== 1 && 's'} for "{$query}"
					</p>

					{!!$results?.length && (
						<ul className="grid gap-2 mt-2 max-h-[60vh] overflow-y-auto">
							{$results?.map((result, key) => (
								<li key={key}>
									<PreviewSmall {...result} />
								</li>
							))}
						</ul>
					)}
				</div>
			)}
		</div>
	)
}

export default Search

const query = graphql`query Search {
	allSanityArticle {
		nodes {
			title
			date
			featuredImage { asset { gatsbyImageData(placeholder: NONE, aspectRatio: 1, width: 60) } }
			metaInfo { ...metaInfo }
		}
	}
}`

import sanityClient from '@sanity/client'
import ImageUrlBuilder from '@sanity/image-url'

export const config = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  apiVersion: '2020-01-01',
}

export const client = sanityClient(config)

export const urlFor = image => ImageUrlBuilder(config).image(image)

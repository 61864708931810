import { Link } from 'gatsby'
import React from 'react'
import Img from '../Img'
import { GoChevronRight } from 'react-icons/go'
import resolveUrl from '@/utils/resolve-url'

const Preview = ({ title, date, featuredImage, metaInfo, tag: Title = 'h3' }) => {
	const slug = metaInfo?.slug?.current

	if (!slug) return null

	return (
		<article>
			<Link className="group block" to={resolveUrl(date, slug)}>
				<div className="transition-all group-hover:brightness-110">
					{featuredImage
						? <Img image={featuredImage} alt={title} />
						: <div className="aspect-[1.5] bg-chestnut/20" />
					}
				</div>

				<div className="grid gap-2 pt-4 md:px-4">
					<Title className="font-bold line-clamp">{title}</Title>

					{!!metaInfo.description && (
						<p className="line-clamp text-sm" style={{ '--lines': 4 }}>
							{metaInfo.description}
						</p>
					)}

					<p className="with-icon justify-start text-chestnut technical font-bold text-sm link-hover">
						Read more <GoChevronRight />
					</p>
				</div>
			</Link>
		</article>
	)
}

export default Preview

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Preview from './Preview'
import Carousel from '../Carousel'

const ReadNext = ({ current }) => {
	const { allSanityArticle } = useStaticQuery(query)

	const articles = allSanityArticle.nodes
		.filter(({ id }) => id !== current)
		.slice(0, 3)

	return (
		<section className="section border-t border-ink/10">
			<h2 className="h2 !font-sans font-bold mb-8">Read This Next...</h2>

			<Carousel
				className="grid md:grid-cols-3 gap-8"
				items={articles}
				component={Preview}
			/>
		</section>
	)
}

export default ReadNext

const query = graphql`query ReadNext {
	allSanityArticle(sort: { fields: date, order: DESC }) {
		nodes {
			...articlePreview
		}
	}
}`

import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share'
import { IoPaperPlaneSharp } from 'react-icons/io5'
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'

const Actions = () => {
	const [$url, set$url] = useState('')

	useEffect(() => {
		if (typeof window === 'undefined') return null
		set$url(window.location.href)
	}, [set$url])

	return (
		<aside className="
			flex md:flex-col items-center gap-8 text-chestnut text-3xl self-start md:sticky-below-header
			<md:gap-4 <md:justify-between <md:text-xl
		">
			<Link to="/resources" title="Back to Resources">
				<HiArrowNarrowLeft />
			</Link>

			<hr className="border border-ink/10 md:w-[1.5em] md:border-b <md:h-[1.5em] <md:border-r" />

			<EmailShareButton url={$url}>
				<IoPaperPlaneSharp />
			</EmailShareButton>

			<LinkedinShareButton url={$url}>
				<FaLinkedin />
			</LinkedinShareButton>

			<TwitterShareButton url={$url}>
				<FaTwitter />
			</TwitterShareButton>

			<FacebookShareButton url={$url}>
				<FaFacebook />
			</FacebookShareButton>
		</aside>
	)
}

export default Actions

import React from 'react'
import { Link } from 'gatsby'
import resolveUrl from '@/utils/resolve-url'
import Img from '../Img'

const PreviewSmall = ({ title, date, featuredImage, metaInfo }) => {
	const slug = metaInfo?.slug?.current

	if (!slug) return null

	return (
		<article>
			<Link className="group grid grid-cols-[60px,1fr] gap-4 items-center" to={resolveUrl(date, slug)}>
				<div className="transition-all group-hover:brightness-110">
					{!!featuredImage
						? <Img image={featuredImage} />
						: <div className="aspect-square bg-chestnut/20" />
					}
				</div>
				<div className="line-clamp font-bold group-hover:text-chestnut">{title}</div>
			</Link>
		</article>
	)
}

export default PreviewSmall

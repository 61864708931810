import React from 'react'
import Img from '@/lib/Img'
import { MdPlayArrow } from 'react-icons/md'
import clsx from 'clsx'

export const Video = ({ url, title, className }) => (
	<iframe
		className={clsx('aspect-video w-full', className)}
		src={url}
		title={title}
		loading="lazy"
	/>
)

export const VideoTrigger = ({ poster, title, onClick }) => (
	<figure className="relative" title={title}>
		{poster
			? <Img as="div" image={poster} />
			: <div className="aspect-video w-full" />
		}

		<button
			className="absolute inset-0 grid place-content-center w-full bg-black/50 text-white"
			onClick={onClick}
		>
			<span className="technical with-icon text-sm">
				<MdPlayArrow />
				Watch video
			</span>
		</button>
	</figure>
)
